<template>
    <div class="legal-layout">
      <!-- header for legal pages -->
       <div class="border-b px-5 py-2.5">
        <div class="w-full bg-[#fdfdfd]">
            <NuxtLink to="/"><img src="/icon.png" alt="Simpliflow Logo Image" class="h-[50px]"></NuxtLink>
          </div>
       </div>
       <!-- page content  -->
      <main>
        <slot />
      </main>
      <!-- footer -->
       <div class="flex justify-center gap-5 py-2.5 px-2.5 border-t">
        <NuxtLink to="/legal/privacy">Privacy Policy</NuxtLink>
        <NuxtLink to="/legal/tos">Terms of Service</NuxtLink>
        <NuxtLink to="/legal/sla">Service Level Agreement (SLA)</NuxtLink>
       </div>
    </div>
  </template>